import { render, staticRenderFns } from "./SalesFunnel.vue?vue&type=template&id=6d72220a&scoped=true&"
import script from "./SalesFunnel.vue?vue&type=script&lang=js&"
export * from "./SalesFunnel.vue?vue&type=script&lang=js&"
import style0 from "./SalesFunnel.vue?vue&type=style&index=0&id=6d72220a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d72220a",
  null
  
)

export default component.exports