<template>
	<div class="sales-funnel position-relative w-100 h-100 d-flex flex-column overflow-hidden">
		<div class="control-buttons position-relative p-4 d-flex justify-content-between align-items-center">
			<button type="button" class="button btn btn-custom-light w-auto d-flex align-items-center overflow-hidden"
			        v-on:click="changeDirection">
				<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'directions']"
				                   v-bind:class="{'vertical': direction === 'vertical'}"></font-awesome-icon>
				<span class="name font-weight-bold text-truncate">Change Direction</span>
			</button>
			<button type="button" class="button btn btn-custom-light w-auto d-flex align-items-center overflow-hidden"
			        v-on:click="exportCSV">
				<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'file-csv']"></font-awesome-icon>
				<span class="name font-weight-bold text-truncate">Export CSV</span>
			</button>
		</div>
		<div id="salesFunnel" class="container-funnel-graph scroll-bar position-relative w-100 h-100 p-4 d-flex overflow-auto">
			<vue-funnel-graph v-bind:width="funnelWidth"
			                  v-bind:height="funnelHeight"
			                  v-bind:labels="labels"
			                  v-bind:values="funnelValues"
			                  v-bind:direction="direction"
			                  v-bind:gradient-direction="gradientDirection"
			                  v-bind:animated="true"
			                  v-bind:display-percentage="true"
			></vue-funnel-graph>
		</div>
	</div>
</template>

<script>
import { VueFunnelGraph } from 'vue-funnel-graph-js';
import FileSaver from "file-saver";
import {toCsv} from "@/worker/file-export.worker";

export default {
	name: "SalesFunnel",
	
	components: {
		VueFunnelGraph
	},
	
	data() {
		return {
			labels: ["Lead / Opportunity", "Quotations", "Sales"],
			funnelWidth: 1320,
			funnelHeight: 400,
			direction: "horizontal",
			gradientDirection: "horizontal",
		}
	},
	
	computed: {
		funnelValues() {
			const requests = this.$store.state.contents.quotationRequests.length;
			const salesQuotes = this.$store.state.contents.salesQuotations.length;
			const salesOrders = this.$store.state.contents.salesOrders.length;
			return [requests, salesQuotes, salesOrders];
		},
	},
	
	methods: {
		changeDirection() {
			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;
			if (this.direction === "horizontal") {
				this.direction = "vertical";
				this.gradientDirection = "vertical";
				this.funnelWidth = windowWidth - 170;
				this.funnelHeight = windowHeight - 200;
			} else {
				this.direction = "horizontal";
				this.gradientDirection = "horizontal";
				this.funnelWidth = 1320;
				this.funnelHeight = 400;
			}
		},
		
		async exportCSV() {
			const csvData = {
				titles: ["Lead / Opportunity", "Quotations", "Sales"],
				values: this.funnelValues
			}
			const file = await toCsv(csvData);
			FileSaver.saveAs(file, `SalesFunnel.csv`);
		},
	}
}
</script>

<style lang="scss" scoped>
.control-buttons {
	min-width: 40px;
}

.icon {
	width: 22px;
	height: 22px;
	
	transition: all .2s ease;
	
	&.vertical {
		transform: rotateZ(90deg);
	}
}

.container-funnel-graph {
	min-height: 0;
	
	.svg-funnel-js {
		:last-child {
			:first-child {
				color: $color-theme-text-icon-light;
			}
			
			:nth-child(2) {
				color: $color-theme-text-icon;
			}
		}
	}
}
</style>
